<template>
  <div class="firstBox">
    <div class="itembox">
      <div class="title">微信小程序AppID：</div>
      <div class="content">
        {{ wxPay.app_id }}
      </div>
    </div>
    <div class="itembox">
      <div class="title">商户号Mch_ID：</div>
      <div v-if="islook == 1" class="content">{{ wxPay.m_id }}</div>
      <div v-else class="content">
        <el-input placeholder="请填写商户号" :spellcheck="false" v-model="wxPay.m_id"></el-input>
        <img src="@/assets/image/isUpdate.png" alt="" />
      </div>
    </div>
    <div class="itembox">
      <div class="title">小程序密钥：</div>
      <div v-if="islook == 1" class="content">{{ wxPay.api_key }}</div>
      <div v-else class="content">
        <el-input placeholder="请填写支付秘钥" :spellcheck="false" v-model="wxPay.api_key"></el-input>
        <img src="@/assets/image/isUpdate.png" alt="" />
      </div>
    </div>
    <div class="itembox">
      <div class="title">apiclient_cert：</div>
      <div class="content">
        <div v-if="islook == 1">{{ wxPay.apiclient_cert }}</div>
        <div v-else class="upBox">
          <el-input placeholder="请上传apiclient_cert证书" disabled v-model="wxPay.apiclient_cert"></el-input>
          <p @click="lookexamplesFlag" class="lookJc">查看设置教程</p>
          <div class="uploadFile">
            上传文件
            <input type="file" ref="inputer0" @change="getFile(0)" />
          </div>
        </div>
      </div>
    </div>
    <div class="itembox">
      <div class="title">apiclient_key：</div>
      <div class="content">
        <div v-if="islook == 1">{{ wxPay.apiclient_key }}</div>
        <div v-else class="upBox">
          <el-input placeholder="请上传apiclient_key证书" disabled v-model="wxPay.apiclient_key"></el-input>
          <div class="uploadFile">
            上传文件
            <input type="file" ref="inputer1" @change="getFile(1)" />
          </div>
        </div>
      </div>
    </div>
    <LookExamples ref="LookExamples" v-show="lookexamples" @hidden="hiddenexamples"></LookExamples>
  </div>
</template>

<script>
import LookExamples from '@/components/previewImg';
export default {
  props: {
    islook: {
      type: Number,
      default: 0,
    },
    storeId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    LookExamples,
  },
  data() {
    return {
      wxPay: {
        app_id: '',
        m_id: '',
        api_key: '',
        apiclient_cert: '',
        apiclient_key: '',
      },
      id: -1,
      isHave: true,
      lookexamples: false,
    };
  },
  methods: {
    lookexamplesFlag() {
      this.lookexamples = true;
      this.$refs.LookExamples.getImgList(15);
    },
    hiddenexamples() {
      this.lookexamples = false;
    },
    // 上传文件
    getFile(status) {
      let files = null;
      let that = this;
      if (status == 0) {
        files = this.$refs.inputer0.files[0];
      } else if (status == 1) {
        files = this.$refs.inputer1.files[0];
      }
      let formData = new FormData();
      formData.append('cert', files);
      formData.append('store_id', this.storeId);

      this.$axios.uploadFile(this.$api.upload.addCert, formData).then(res => {
        if (res.code == 0) {
          that.$message.success('上传成功');
          if (status == 0) {
            this.$set(this.wxPay, 'apiclient_cert', res.result.path);
          } else {
            this.$set(this.wxPay, 'apiclient_key', res.result.path);
          }
        } else {
          that.$message.error(`${res.msg}`);
        }
      });
    },
    // 保存信息
    preservation() {
      let that = this;
      if (!this.wxPay.m_id || !this.wxPay.api_key || !this.wxPay.apiclient_cert || !this.wxPay.apiclient_key) {
        this.$message({
          message: '请将配置项填写完整！！！',
          type: 'warning',
        });
        return;
      }
      let sets = {
        app_id: this.wxPay.app_id,
        m_id: this.wxPay.m_id,
        api_key: this.wxPay.api_key,
        apiclient_cert: this.wxPay.apiclient_cert,
        apiclient_key: this.wxPay.apiclient_key,
      };
      let url = '';
      let params = {
        sets,
        type: 30,
        store_id: this.storeId,
      };
      console.log(this.storeId);
      if (this.isHave) {
        url = this.$api.set.Settingedit;
        params.id = this.id;
      } else {
        url = this.$api.set.Settingadd;
      }
      this.$axios.post(url, params).then(res => {
        if (res.code == 0) {
          that.$emit('WxPaySureSu', that.wxPay.m_id);
          that.$message({
            message: '保存成功',
            type: 'success',
          });
          that.isHave = true;
          return;
        }
        that.$message({
          message: '保存失败',
          type: 'error',
        });
      });
    },
    // 获取信息
    getInfo() {
      let that = this;
      this.$axios
        .post(this.$api.set.Settinginfo, {
          type: 30,
          store_id: this.storeId,
        })
        .then(res => {
          if (res.code == 0) {
            if (res.result.info == '') {
              that.isHave = false;
              return;
            }
            that.id = res.result.info.id;
            that.wxPay = res.result.info.sets;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.firstBox {
  .itembox {
    display: flex;
    align-items: center;
    .title {
      flex: 0 0 150px;
      flex-shrink: 0;
    }
    .content {
      flex: 1;
      flex-shrink: 0;
      padding: 23px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      .upBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .el-input {
        width: 70%;
        line-height: normal !important;
        /deep/ input {
          line-height: normal !important;
          letter-spacing: 1px;
          border: 1px solid transparent;
          padding: 0;
          height: inherit;
        }
      }
      .uploadFile {
        position: relative;
        color: #1467ff;
        cursor: pointer;
        input {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
        }
      }
      .lookJc {
        color: #1467ff;
        cursor: pointer;
      }
    }
  }
}
</style>
